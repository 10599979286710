@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap");
@import url("https://fonts.cdnfonts.com/css/blanka");

html {
	font-family: "Montserrat", sans-serif;
	background-color: #ffff;
}

* {
	box-sizing: border-box;
}

.content {
	margin-left: 1rem;
	z-index: 0;

	overflow-y: auto; /* Enable vertical scrollbar for the content */
	-webkit-overflow-scrolling: touch;
}

.css-gwpx1 {
	/* Your custom styles here */
	width: 100px;
	min-width: 100px;
	border-color: #ffff;
	/* Add any other styles you want to override or modify */
}

@media only screen and (max-width: 600px) {
	.content {
		margin-left: 0rem;
	}
}
