.svg-inline--fa {
	font-size: 5px;
	width: 1em;
	height: 1em;
}

.fa-star {
	font-size: 1rem;
	width: 1em;
	height: 1em;
}

.fa-w-18 {
	font-size: 1rem;
	width: 1em;
	height: 1em;
}
